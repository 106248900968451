.heading {
    @apply mb-2 text-3xl font-bold text-center text-interface-900 ipad:text-xl mobile:text-lg;
}
.tagline {
    @apply mb-6 text-sm font-normal text-center text-interface-600 mobile:text-sm;
}
.bgImage {
    background-image: url('/auth-bg.png');
    @apply overflow-hidden bg-center bg-no-repeat bg-cover;
}
.authLogo {
    @apply w-[273.52px] pb-8 m-auto;
}
.authFormSection {
    @apply relative w-[756px] flex-shrink-0 overflow-y-auto px-24 pt-[72px] 4xl:w-[660px] 3xl:w-[756px] 2xl:w-[550px] 2xl:px-16 2xl:pt-9 md:w-[400px] bg-common-white md:px-8;
}

.btn {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    @apply relative flex h-[42px] flex-shrink-0 items-center justify-center overflow-hidden rounded-md border px-4 text-sm font-medium text-common-white outline-0 focus:ring-0;
}
.btn .labelText {
    @apply leading-[1] font-theme;
}
.btn.sm {
    @apply h-[34px] rounded-md px-3 text-sm;
}
.btn .spinner {
    @apply absolute top-0 left-0 flex items-center justify-center w-full h-full opacity-0;
}
.btn.md {
    @apply h-12 text-base ipad:h-10;
}
.btn.md .labelText {
    @apply text-base font-semibold ipad:text-sm;
}
.btn.base {
    @apply h-[38px] text-sm;
}
.btn.other {
    @apply h-[41px];
}
.btn.xs {
    @apply h-[42px] px-3.5 text-sm;
}
.sidebarLink {
    @apply h-[88px] w-[88px] text-interface-600 flex flex-col border-none;
}
.primary.primary {
    @apply transition-all border-transparent bg-primary-500 text-common-white hover:bg-primary-600;
}
.secondary.secondary {
    @apply border-0 bg-interface-200 text-interface-600 hover:border-interface-700;
}
.interface.interface {
    @apply border-0 bg-interface-500 text-common-white;
}
.success.success {
    @apply bg-success-500 text-common-white hover:bg-success-700;
}
.live.live {
    @apply border-none bg-[#EC001F] uppercase text-common-white;
}
.gray.gray {
    @apply border-0 shadow-sm text-interface-600 bg-interface-200;
}
.light-gray.light-gray {
    @apply text-sm border-interface-500 bg-interface-500 text-interface-200 hover:bg-interface-500;
}
.outline.outline {
    @apply shadow-sm border-primary-300 bg-common-white text-primary-500;
}
.outline-primary.outline-primary {
    @apply shadow-sm border-primary-500 bg-common-white text-primary-500;
}
.outline-interface.outline-interface {
    @apply bg-transparent shadow-sm border-interface-200 text-interface-600 ipad:bg-common-white;
}
.outline-secondary.outline-secondary {
    @apply border-interface-700 bg-interface-800 text-common-white;
}
.dropdown-link.dropdown-link {
    @apply h-11 flex-col items-start justify-center rounded-none border-none px-[17px] py-0 hover:bg-primary-100 hover:bg-opacity-40 hover:text-common-white;
}
.dropdown-link.dropdown-link i {
    @apply text-[18px] text-interface-500;
}
.dropdown-link.dropdown-link span {
    @apply text-sm leading-3 text-interface-900;
}
.dark.dark {
    @apply border-interface-600 bg-interface-600 text-interface-200;
}
.white.white {
    @apply font-medium border border-interface-300 bg-common-white text-interface-700;
}
.white.white .labelText {
    @apply xl:text-[12px];
}
.danger.danger {
    @apply border-0 bg-danger-500 text-common-white hover:bg-danger-600;
}
.delete.delete i {
    @apply text-[22px] text-danger-500;
}
.icon-btn.btn {
    @apply w-auto h-auto p-0 border-0;
}
.icon-btn.btn i {
    @apply mr-0;
}
.spinnerInner {
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    @apply h-6 w-6 rounded-full border-[3px] border-t-[3px] border-interface-300 border-t-primary-500;
}
.icon-btn.btn .spinnerInner {
    @apply hidden;
}
.danger-outline.danger-outline {
    @apply border border-danger-500 text-danger-500 bg-common-white;
}
/* .danger-outline.danger-outline:hover i {
    @apply text-common-white;
} */
.danger-outline.danger-outline i {
    @apply text-[22px] text-danger-500;
}
.danger-outline.danger-outline.sm i {
    @apply text-base;
}
.link.link {
    @apply h-auto py-0 border-0 shadow-none text-interface-900;
}
.link-primary.link-primary {
    @apply h-auto p-0 py-0 pb-0.5 border-0 border-b rounded-none shadow-none text-primary-500;
}
.link.link .labelText {
    @apply text-sm font-medium;
}
.header-link.header-link {
    @apply p-0 border-0 shadow-none text-interface-500;
}
.google.google {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    @apply border border-interface-300 bg-common-white text-interface-900;
}
.google-secondary.google-secondary {
    @apply border border-interface-300 bg-common-white text-interface-900;
}
.normalizeLink.normalizeLink {
    @apply h-auto px-0 py-0 border-0 shadow-none text-primary-500;
}
.normalizeLink.normalizeLink span {
    @apply text-sm;
}
.btn.link-xs span {
    @apply py-[3px] text-sm font-normal text-common-white;
}
.btn.link-sm span {
    @apply py-1 text-sm text-common-white;
}
.btn .text-justify-start {
    @apply justify-start;
}
.btn .iconPosition.right {
    @apply flex-row-reverse;
}
.btn .iconPosition.right i {
    @apply ml-2 mr-0;
}
.btn[type='button'][disabled] {
    @apply opacity-50;
}
.btn[type='submit'][disabled],.btn.customBtnLoader {
    @apply text-transparent border-transparent opacity-50 pointer-events-none;
}
.btn.customBtnLoader .spinner, .btn[type='submit'][disabled] .spinner {
    @apply opacity-100 pointer-events-none;
}
.uploadLoader .btn[type='button'][disabled] .spinner {
    @apply opacity-100 pointer-events-none;
}
.btn[type='submit'][disabled] .labelText {
    @apply opacity-0;
}
.uploadLoader .btn[type='button'][disabled] .labelText,
.uploadLoader .btn[type='button'][disabled] .iconStyle {
    @apply opacity-0;
}
/* Loader */
.loader {
    @apply relative w-full mb-2;
}
/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loaderMsg {
    @apply ml-2 text-sm text-interface-900;
}

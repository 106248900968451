.sidebar {
    @apply fixed top-0 flex flex-col h-screen w-[104px] items-center justify-center border-r border-interface-300 bg-common-white pt-24;
}
.sidebarSmall .sidebar {
    @apply w-[56px];
}
.sidebarMenuList {
    @apply w-full p-0;
}
.sidebarSmall .dropdown {
    @apply hidden;
}
.sidebarSmall .scrollable {
    @apply overflow-x-hidden;
}
.NavsList {
    @apply mr-1  h-[44px] flex items-center;
}
.NavsList:nth-child(6) {
    @apply ml-auto;
}
.NavsList a {
    @apply px-4 block uppercase text-interface-600 text-[13px] font-medium;
}
.NavsList a span {
    @apply flex items-center justify-center;
}
.NavsList a i {
    @apply mr-2 text-lg;
}
.NavsList.active {
    @apply relative bg-primary-50;
}
.NavsList.active:before {
    content: '';
    @apply absolute bg-primary-500 h-0.5 w-full -bottom-[1px];
}
.menuItem a span {
    @apply flex items-center mb-1 px-[6px] hover:bg-interface-100 hover:rounded-md h-[72px] font-medium justify-center flex-col text-center text-xs hover:text-common-black text-interface-600 uppercase;
}
.menuItem a i {
    @apply text-base mb-1.5;
}
.sidebarSmall .menuItem a span {
    @apply text-[0] p-[6px] justify-center;
}
.sidebarSmall .menuItem a i {
    @apply static mr-0;
}
/* .menuItem i {
    @apply hover:text-common-black !important;
} */
.menuItem li {
    @apply relative w-[88px];
}
.menuItem li a {
    @apply flex flex-col;
}
.menuItem li .dropdown {
    @apply absolute right-4 top-4 text-[6px] text-interface-600;
}
.menuItem li.active a span {
    @apply font-medium rounded-md bg-primary-50 text-primary-500 hover:text-common-black hover:bg-interface-100;
}
.menuItem li.active .dropdown {
    @apply text-interface-900;
}
/* .menuItem li.active a span i {
    @apply text-primary-500 hover:text-common-black;
} */
.menuItem li .subMenu {
    @apply hidden;
}
.menuItem li.active .subMenu {
    @apply block;
}
.menuItem li .subMenu li a span {
    @apply ml-6 bg-transparent pl-[12px] text-interface-700;
}
.menuItem li .subMenu li.active a span {
    @apply font-medium text-interface-900;
}
/* .menuItem li.active .subMenu li a span.active {
    @apply bg-transparent text-primary-600;
} */
/* .menuItem li.active .subMenu {
    @apply relative;
} */
.menuItem li .subMenu::after {
    content: '';
    @apply absolute left-[13px] top-[6px] h-[90%] w-0.5 rounded-sm bg-body-bg;
}
.sidebarSmall .menuItem li .subMenu::after {
    @apply left-[19px];
}
.NavsSection {
    @apply px-8 shadow-md bg-common-white;
}
.Navs {
    @apply flex;
}
.NavsList {
    @apply mr-1  h-[44px] flex items-center;
}
.NavsList:nth-child(6) {
    @apply ml-auto;
}
.NavsList a {
    @apply px-4 block uppercase text-interface-600 text-[13px] font-medium;
}
.NavsList a span,
.NavsList span {
    @apply flex items-center justify-center;
}
.NavsList a i,
.NavsList span i {
    @apply mr-2 text-lg;
}
.NavsList span i {
    @apply relative top-0;
}
.NavsList.active {
    @apply relative bg-primary-50;
}
.NavsList.active:before {
    content: '';
    @apply absolute bg-primary-500 h-0.5 w-full -bottom-[1px];
}
.NavsList.active a {
    @apply text-primary-500;
}
.TabNavigation {
    @apply bg-transparent shadow-none p-0 relative top-[8px];
}
.TabNavigation li {
    @apply mr-[22px] last-of-type:mr-0;
}
.TabNavigation a {
    @apply p-0 text-sm capitalize;
}
.TabNavigation .NavsList.active {
    @apply bg-transparent;
}
.TabNavigation .NavsList {
    @apply h-auto pb-3;
}

@font-face {
    font-family: 'icomoon';
    src: url('/fonts/icomoon.eot?r2utce');
    src:
        url('/fonts/icomoon.eot?r2utce#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.ttf?r2utce') format('truetype'),
        url('/fonts/icomoon.woff?r2utce') format('woff'),
        url('/fonts/icomoon.svg?r2utce#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-split:before {
    content: "\e900";
  }
  .icon-merge:before {
    content: "\e901";
  }
  .icon-unarchive:before {
    content: "\e902";
  }
  .icon-left-circle:before {
    content: "\e903";
  }
  .icon-right-circle:before {
    content: "\e904";
  }
  .icon-clock-card:before {
    content: "\e905";
  }
  .icon-config:before {
    content: "\e906";
  }
  .icon-person:before {
    content: "\e907";
  }
  .icon-upload:before {
    content: "\e908";
  }
  .icon-filter:before {
    content: "\e909";
  }
  .icon-more:before {
    content: "\e90a";
  }
  .icon-shrink:before {
    content: "\e924";
  }
  .icon-expand:before {
    content: "\e925";
  }
  .icon-compare:before {
    content: "\e90b";
  }
  .icon-external-link:before {
    content: "\e90c";
  }
  .icon-door-open:before {
    content: "\e90d";
  }
  .icon-file:before {
    content: "\e90e";
  }
  .icon-arrow-up:before {
    content: "\e90f";
  }
  .icon-sort:before {
    content: "\e910";
  }
  .icon-menu:before {
    content: "\e9c9";
  }
  .icon-link:before {
    content: "\e9c7";
  }
  .icon-dash-fill:before {
    content: "\e911";
  }
  .icon-move-down:before {
    content: "\e978";
  }
  .icon-move-up:before {
    content: "\e9c2";
  }
  .icon-download-solid:before {
    content: "\e9bb";
  }
  .icon-switch-filled:before {
    content: "\e9af";
  }
  .icon-card-image:before {
    content: "\e92c";
  }
  .icon-info:before {
    content: "\e932";
  }
  .icon-info1:before {
    content: "\e973";
  }
  .icon-close:before {
    content: "\e974";
  }
  .icon-unlock:before {
    content: "\e912";
  }
  .icon-lock:before {
    content: "\e913";
  }
  .icon-delete:before {
    content: "\e914";
  }
  .icon-copy:before {
    content: "\e915";
  }
  .icon-add:before {
    content: "\e916";
  }
  .icon-add-item:before {
    content: "\e917";
  }
  .icon-arrow-down:before {
    content: "\e918";
  }
  .icon-arrow-left:before {
    content: "\e919";
  }
  .icon-arrow-right:before {
    content: "\e91a";
  }
  .icon-backlog:before {
    content: "\e91b";
  }
  .icon-branches:before {
    content: "\e91c";
  }
  .icon-building:before {
    content: "\e91d";
  }
  .icon-calendar:before {
    content: "\e91e";
  }
  .icon-check:before {
    content: "\e91f";
  }
  .icon-check-circle:before {
    content: "\e920";
  }
  .icon-close1:before {
    content: "\e921";
  }
  .icon-download:before {
    content: "\e922";
  }
  .icon-edit:before {
    content: "\e923";
  }
  .icon-folder:before {
    content: "\e926";
  }
  .icon-full-screen:before {
    content: "\e927";
  }
  .icon-grid:before {
    content: "\e928";
  }
  .icon-hex:before {
    content: "\e929";
  }
  .icon-image:before {
    content: "\e92a";
  }
  .icon-logout:before {
    content: "\e92b";
  }
  .icon-refresh:before {
    content: "\e92d";
  }
  .icon-search:before {
    content: "\e92e";
  }
  .icon-settings:before {
    content: "\e92f";
  }
  .icon-trash:before {
    content: "\e944";
  }
  .icon-users:before {
    content: "\e947";
  }
  .icon-action:before {
    content: "\e949";
  }
  .icon-arrow-top:before {
    content: "\e930";
  }
  .icon-check-switch:before {
    content: "\e931";
  }
  .icon-convert:before {
    content: "\e945";
  }
  .icon-archive:before {
    content: "\e946";
  }
  .icon-transfer:before {
    content: "\e943";
  }
  .icon-attachment:before {
    content: "\e933";
  }
  .icon-clock:before {
    content: "\e934";
  }
  .icon-mention:before {
    content: "\e935";
  }
  .icon-user-avatar-circle:before {
    content: "\e936";
  }
  .icon-due:before {
    content: "\e937";
  }
  .icon-warning:before {
    content: "\e938";
  }
  .icon-crm:before {
    content: "\e939";
  }
  .icon-user:before {
    content: "\e93a";
  }
  .icon-email:before {
    content: "\e93b";
  }
  .icon-flag:before {
    content: "\e93c";
  }
  .icon-note:before {
    content: "\e93d";
  }
  .icon-call:before {
    content: "\e93e";
  }
  .icon-task:before {
    content: "\e93f";
  }
  .icon-board:before {
    content: "\e940";
  }
  .icon-people:before {
    content: "\e941";
  }
  .icon-bell:before {
    content: "\e942";
  }
  .icon-check-circle-outline:before {
    content: "\e948";
  }
  .icon-graph-bar:before {
    content: "\e94a";
  }
  .icon-plugin:before {
    content: "\e94b";
  }
  .icon-address-book:before {
    content: "\e94c";
  }
  .icon-prev:before {
    content: "\e98c";
  }
  .icon-next:before {
    content: "\e9c1";
  }
  